import Logo from "./Logo";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {logout, memberlogin, checkmemberlogin, getbalance, setStep, bannerlist} from "../store/user";
import {Offcanvas} from "react-bootstrap";
import {useEffect, useState, useRef} from "react";
import io from "socket.io-client";
import { addCommas, ENDPOINT, WEBTOKEN, LG, WEBFULLNAME as WEBNAME } from '../config/Index'

const lgtext = {
    lao:{
      1:'ຫຼີ້ນເກມ',
      2:'ຂໍ້ມູນສ່ວນໂຕ',
      3:'ປ່ຽນລະຫັດຜ່ານ',
      4:'ແນະນຳໝູ່',
      5:'ຄືນຍອດເສຍ',
      6:'ປະຫວັດ',
      7:'ໂປໂມຊັ້ນ',
      8:'ອອກ​ຈາກ​ລະ​ບົບ',
      9:'ຝາກເງິນ',
      10:'ຖອນເງິນ',
      11:`${WEBNAME} ເວປໄຊ້ຜູ້ໃຫ້ບໍລິການເດິມພັນຕ່າງໆຈາກທົ່ວໂລກ ຕະຫຼອດ 24 ຊົ່ວໂມງ`,
      12:'ລະຫັດຜ່ານ',
      13:'ຍຸສເຊີ້',
      14:'ເຂົ້າສູ່ລະບົບ',
      15:'ສະໝັກສະມາຊິກ',
      16:'ຫນ້າທຳອິດ',
      17:'ກິລາ',
      18:'ຄາສິໂນ',
      19:'ສະລ໊ອດ',
      20:'ໂປໂມຊັ້ນ',
      21:'ຕິດຕໍ່ - ສອບຖາມ',
      22:'ເມນູ',
      23:'ເຂົ້າຫຼີ້ນ',
    },
    thai:{
      1:'เข้าเล่น',
      2:'ข้อมูลส่วนตัว',
      3:'เปลี่ยนรหัสผ่าน',
      4:'แนะนำเพื่อน',
      5:'คืนยอดเสีย',
      55:'คืนค่าคอมฯ',
      6:'ประวัติ',
      7:'โปรโมชั่น',
      8:'ออกจากระบบ',
      9:'ฝากเงิน',
      10:'ถอนเงิน',
     // 11:`${WEBNAME} เว็บเดิมพันออนไลน์ 24 ชม`,
      11:`${WEBNAME} เล่นง่าย ฝากง่าย ถอนไม่ติดเทิร์น เจ้าแรก เจ้าเดียว มั่นคงปลอดภัย ตรงไปตรงมา`,
      12:'รหัสผ่าน',
      13:'ยูสเซอร์เนม',
      14:'เข้าสู่ระบบ',
      15:'สมัครสมาชิก',
      16:'หน้าแรก',
      17:'กีฬา',
      18:'คาสิโน',
      19:'สล๊อต',
      20:'โปรโมชั่น',
      21:'ติดต่อ-สอบถาม',
      22:'เมนู',
      23:'เข้าเล่น',
    }
  }
function NavBar() {
    const user = useSelector(state => state.user)
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        //startsocket();
        const nio = io(ENDPOINT);
    
        nio.on("sentchat", (messageNew) => {

        if(`${localStorage.getItem('username')}` === `${messageNew?.username}`) {
            
            if(messageNew?.status == 3) {
                // ยืนยันฝากแล้ว
                dispatch(getbalance({}))
                dispatch(setStep({accountdeposit:messageNew}))
            } else  if(messageNew?.status == 4) {
                // ยกเลิกฝาก
                dispatch(setStep({accountdeposit:messageNew}))
            } else  if(messageNew?.wsuccess == 3) {
                // ยืนยันถอน
                dispatch(setStep({accountwithdraw:messageNew}))
                
            } else  if(messageNew?.wsuccess == 4) {
                // ยกเลิกถอน
                dispatch(setStep({accountwithdraw:messageNew}))

            } else if(parseInt(messageNew?.checkqrcode) === 1){
                // แอดมินแนบสลิปมาแล้ว
                dispatch(setStep({step:3, accountdeposit:messageNew}))
            } else {
                // ส่ง Qrcode แล้ว
                //dispatch(setStep(4))
                //dispatch(setQrcode(messageNew?.qrcode))
                //dispatch(setSid(messageNew?.sid))
                //dispatch(setMtimestamp(messageNew?.mtimestamp))
            }
        }
          // ส่งไปอับเดทให้ ผู้ใช้งคนอื่นๆรับรู้
        });
        }, [])

    const signOut = async () => {
        await dispatch(logout());
        return navigate("/");
    }

    useEffect(() => {
        handleClose();
    }, [location]);

    useEffect(() => {

        dispatch(bannerlist())
        dispatch(checkmemberlogin())
        dispatch(getbalance({}))
    }, [])

    const txtusername = useRef(null)
    const txtpassword = useRef(null)

    const loginpage = () => {
        const username = txtusername.current.value;
        const password = txtpassword.current.value;
        if(!username){
            txtusername.current.focus()
        } else if(!password && username){
            txtpassword.current.focus()
        } else {
            const data = {
                username,
                password,
                WEBTOKEN
            }
            if(user.isLoading === false) {
                dispatch(memberlogin(data));
            }
            
        }
        
    }

    const loadcredit = () => {
        if(user.isLoading === false) {
            dispatch(getbalance({}))
        }
    }
   
    return (
        <>

            <Offcanvas show={show} onHide={handleClose} placement={'end'}>
                <div>
                    <div className="insidebarleft">

                        <div className={'px-3 pt-3 d-flex justify-content-between align-items-center'}>
                            <Link to={'/'}>
                                <Logo style={{maxHeight: 25}}/>
                            </Link>
                            <div onClick={handleClose}>
                                <i className="far fa-2x fa-times pointer"/>
                            </div>
                        </div>


                        {!user.isLogged && <>
                            <ul>
                                <li>
                                    <Link to={'/'}>
                                        <img alt={'home'} src="/images/icon/icon-home.png"/>
                                        หน้าหลัก
                                    </Link>
                                </li>
                                <li>
                                    <Link to={'/'}>
                                        <img alt={'sport'} src="/images/icon/icon-sport.png"/>
                                        กีฬา
                                    </Link>
                                </li>
                                <li>
                                    <Link to={'/'}>
                                        <img alt={'casino'} src="/images/icon/icon-casino.png"/>
                                        คาสิโน
                                    </Link>
                                </li>
                                <li>
                                    <Link to={'/'}>
                                        <img alt={'slot'} src="/images/icon/icon-slot.png"/>
                                        เกมสล็อต
                                    </Link>
                                </li>
                                <li>
                                    <Link to={'/'}>
                                        <img alt={'card'} src="/images/icon/icon-card.png"/>
                                        ไพ่
                                    </Link>
                                </li>
                                <li>
                                    <Link to={'/promotion'}>
                                        <img alt={'promotion'} src="/images/icon/icon-promotion.png"/>
                                        ໂປໂມຊັ້ນ
                                    </Link>
                                </li>
                                <li>
                                    <Link to={'/'}>
                                        <img alt={'contact'} src="/images/icon/icon-contact.png"/>
                                        ติดต่อเรา
                                    </Link>
                                </li>
                            </ul>
                        </>
                        }

                        {user.isLogged && <>
                            <ul>
                                <li>
                                    <Link to={'/'}>
                                        <i className="fas fa-dice-d6"></i>
                                        {lgtext[LG]['1']}
                                    </Link>
                                </li>
                                <li>
                                    <Link to={'/profile'}>
                                        <i className="fas fa-user-alt"></i>
                                        {lgtext[LG]['2']}
                                    </Link>
                                </li>
                                <li>
                                    <Link to={'/change-password'}>
                                        <i className="fas fa-user-lock"></i>
                                        {lgtext[LG]['3']}
                                    </Link>
                                </li>
                             
  <li>
                                    <Link to={'/affiliate'}>
                                        <i className="fas fa-users"></i>
                                        {lgtext[LG]['4']}
                                    </Link>
                                </li>
                              
                              
                                <li>
                                    <Link to={'/cashback'}>
                                        <img src="/images/icon/return.png" alt={'cashback'}/>
                                        {lgtext[LG]['5']}
                                    </Link>
                                </li>
                                {
                                    (WEBTOKEN === 'Sbo95th' || WEBTOKEN === 'PananVIP') &&  <li>
                                    <Link to={'/commission'}>
                                        <img src="/images/icon/com.png" alt={'cashback'}/>
                                        {lgtext[LG]['55']}
                                    </Link>
                                </li>
                                }
                               
                             
                                <li>
                                    <Link to={'/history'}>
                                        <i className="far fa-history"></i>
                                        {lgtext[LG]['6']} 
                                    </Link>
                                </li>
                                <li>
                                    <Link to={'/promotion'}>
                                        <i className="far fa-gift"></i>
                                        {lgtext[LG]['7']}
                                    </Link>
                                </li>
                                <li>
                                    <Link onClick={signOut}>
                                        <i className="far fa-sign-out"></i>
                                        {lgtext[LG]['8']}
                                    </Link>
                                </li>
                            </ul>
                        </>
                        }

                    </div>
                </div>
            </Offcanvas>

            <div className="toplogin">
                <div className="containtoplogin">
                    <div className="topdetaillogin">
                    {lgtext[LG]['11']}
                    </div>
                    <div className="toploginbox">
                        {!user.isLogged && <>
                            <form>
                                <div className="el-input my-1">
                                    <i className="fas fa-user"/>
                                    <input type="text" ref={txtusername} placeholder={lgtext[LG]['13']}  className="inputstyle"/>
                                </div>
                                <div className="el-input my-1">
                                    <i className="fas fa-lock"/>
                                    <input type="password" ref ={txtpassword}  placeholder= {lgtext[LG]['12']} className="inputstyle"/>
                                   
                                </div>
                                <div className="flexcenter">
                                    {
                                        user.isLoadingLogin ? <input type="button" onClick={loginpage} name="button" id="button" value="กำลังล๊อกอิน" disabled className="btn"/> : <input type="button" onClick={loginpage} name="button" id="button" value={lgtext[LG]['14']} className="btn"/>
                                    }
                                        
                                </div>
                            </form>

                            <div className="flexcenter">
                                <Link to={'/register'}>
                                    <button className="btn">
                                    {lgtext[LG]['15']}
                                    </button>
                                </Link>
                            </div>
                        </>
                        }

                        {user.isLogged && <>
                            <div className="flexcenter me-3">
                                <span className="telheader"><img src="/images/icon/goldprofile.png" alt={'profile'}/>{user?.item?.username}</span>
                            </div>
                            <div className="flexcenter me-2">
                                <div className="-balance-container">
                                    <div className="-user-balance js-user-balance f-sm-6 f-7 ">
                                        <div className="-inner-box-wrapper">
                                            <img className="img-fluid -ic-coin" src="/images/icon/coin.png"
                                                 alt="customer image" width="26" height="21"/>
                                            <span id="customer-balance"><span
                                                className="text-green-lighter">{addCommas(parseFloat(user?.item?.balance || 0).toFixed(0))} บาท</span>
                                    </span>
                                        </div>
                                        <button type="button" onClick={loadcredit} className="-btn-balance" id="btn-customer-balance-reload">
                                            
                                            {
                                                user?.isLoading == true ? <div class="loader"></div> : <i className="fas fa-sync-alt f-9 reloadcredit"/>
                                            }
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="flexcenter me-1">
                                <Link to={'/deposit'}>
                                    <button className="btn blue">
                                    {lgtext[LG]['9']}
                                    </button>
                                </Link>
                            </div>

                            <div className="flexcenter">
                                <Link to={'/withdraw'}>
                                    <button className="btn gold">
                                    {lgtext[LG]['10']}
                                    </button>
                                </Link>
                            </div>
                        </>}
                    </div>
                </div>
                {
                        user.item?.status == 3 && <div className="text-danger text-center">ไม่พบข้อมูลสมาชิก กรุณาป้อนใหม่อีกครั้ง</div>
                }
                
            </div>
            


            <div className="second-bar">
                <div className="ctscb">
                    <div className="leftscb">

                        <Link to={'/'}>
                            <Logo/>
                        </Link>
                    </div>
                    <div className="rightscb">

                        {!user.isLogged && <>
                            <ul>
                                <li>
                                    <Link to={'/'}>
                                        <img alt={'home'} src="/images/icon/icon-home.png"/>
                                        {lgtext[LG]['16']}
                                    </Link>
                                </li>
                                <li>
                                    <Link to={'/'}>
                                        <img alt={'sport'} src="/images/icon/icon-sport.png"/>
                                        {lgtext[LG]['17']}
                                    </Link>
                                </li>
                                <li>
                                    <Link to={'/'}>
                                        <img alt={'casino'} src="/images/icon/icon-casino.png"/>
                                        {lgtext[LG]['18']}
                                    </Link>
                                </li>
                                <li>
                                    <Link to={'/'}>
                                        <img alt={'slot'} src="/images/icon/icon-slot.png"/>
                                        {lgtext[LG]['19']}
                                    </Link>
                                </li>
                                {
                                    /*
  <li>
                                    <Link to={'/'}>
                                        <img alt={'card'} src="/images/icon/icon-card.png"/>
                                        ไพ่
                                    </Link>
                                </li>
                                    */
                                }
                              
                                <li>
                                    <Link to={'/promotion'}>
                                        <img src="/images/icon/icon-promotion.png"/>
                                        {lgtext[LG]['20']}
                                    </Link>
                                </li>
                                <li>
                                    <Link to={'/contact'}>
                                        <img src="/images/icon/icon-contact.png"/>
                                        {lgtext[LG]['21']}
                                    </Link>
                                </li>
                            </ul>
                        </>
                        }

                        {user.isLogged && <>
                            <ul>
                                <li>
                                    <Link to={'/'}>
                                        <i className="fas fa-dice-d6"></i>
                                        {lgtext[LG]['1']}
                                    </Link>
                                </li>
                                <li>
                                    <Link to={'/profile'}>
                                        <i className="fas fa-user-alt"></i>
                                        {lgtext[LG]['2']}
                                    </Link>
                                </li>
                                <li>
                                    <Link to={'/change-password'}>
                                        <i className="fas fa-user-lock"></i>
                                        {lgtext[LG]['3']}
                                    </Link>
                                </li>
                                
 <li>
                                    <Link to={'/affiliate'}>
                                        <i className="fas fa-users"></i>
                                        {lgtext[LG]['4']}
                                    </Link>
                                </li>
                                   
                               
                                <li>
                                    <Link to={'/cashback'}>
                                        <img src="/images/icon/return.png"/>
                                        {lgtext[LG]['5']}
                                    </Link>
                                </li>
                                {
                                    (WEBTOKEN === 'Sbo95th' || WEBTOKEN === 'PananVIP') && <li>
                                    <Link to={'/commission'}>
                                        <img src="/images/icon/com.png"/>
                                        {lgtext[LG]['55']}
                                    </Link>
                                </li>
                                }
                                
                                <li>
                                    <Link to={'/history'}>
                                        <i className="far fa-history"></i>
                                        {lgtext[LG]['6']}
                                    </Link>
                                </li>
                                <li>
                                    <Link to={'/promotion'}>
                                        <i className="far fa-gift"></i>
                                        {lgtext[LG]['7']}
                                    </Link>
                                </li>
                                <li>
                                    <a onClick={signOut}>
                                        <i className="far fa-sign-out"></i>
                                        {lgtext[LG]['8']}
                                    </a>
                                </li>
                            </ul>
                        </>
                        }
                    </div>

                    <div className="toploginbox">
                        {!user.isLogged && <>
                            <Link to={'/login'}>
                                <button className="btn">
                                {lgtext[LG]['14']}
                                </button>
                            </Link>
                            <Link to={'/register'}>
                                <button className="btn">
                                {lgtext[LG]['15']}
                                </button>
                            </Link>
                        </>
                        }

                        {user.isLogged && <>
                            <div className="me-1">
                                <div className="telheader ms-auto">
                                    <img src="/images/icon/goldprofile.png" alt={'gold'}/> {user?.item?.username}
                                </div>
                                <div className="-balance-container">
                                    <div className="-user-balance js-user-balance f-sm-6 f-7 ">
                                        <div className="-inner-box-wrapper">
                                            <img className="img-fluid -ic-coin" src="/images/icon/coin.png"
                                                 alt="customer image" width="26" height="21"/>
                                            <span id="customer-balance"><span
                                                className="text-green-lighter">{addCommas(parseFloat(user?.item?.balance || 0).toFixed(0))} บาท</span>
                            </span>
                                        </div>
                                        <button type="button" onClick={loadcredit} className="-btn-balance" id="btn-customer-balance-reload">
                                        {
                                                user?.isLoading == true ? <div class="loader"></div> : <i className="fas fa-sync-alt f-9 reloadcredit"/>
                                            }
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </>
                        }
                    </div>

                    
                    {
                        user.isLogged &&  <div className="x-button-actions d-block d-lg-none" id="account-actions-mobile">
                        <div className="-outer-wrapper">
                            <div className="-left-wrapper">
                        <span className="-item-wrapper">
                            <span className="-ic-img">
                                <span className="-textfooter d-block">
                                    <Link to={'/depoist'}>{lgtext[LG]['9']}</Link>
                                </span>
                            <Link to={'/deposit'}>
                                <img className={'img-fluid'}
                                     src={'/images/icon/deposit.png'}
                                     alt={'deposit'}/>
                            </Link>
                            </span>
                        </span>

                                <span className="-item-wrapper">
                            <span className="-ic-img">
                                <span className="-textfooter d-block">
                                    <Link to={'/withdraw'}>{lgtext[LG]['10']} </Link>
                                </span>
                                <Link to={'/withdraw'}>
                                        <img className={'img-fluid icon'}
                                             src={'/images/icon/withdraw.png'} alt={'withdraw'}/>
                                </Link>
                            </span>
                        </span>
                            </div>

                            <span className="-center-wrapper js-footer-lobby-selector js-menu-mobile-container">
                        <div className="-selected"><h2 className="widgettitle"><Link to={'/withdraw'}>{lgtext[LG]['23']} </Link></h2>
                        <Link to={'/'}>
                                  <img className={'img-fluid mb-1'}
                                       src={'/images/icon/menu-center.png'} alt={'privilege'}/>
                            </Link>
                        </div>
                    </span>

                            <div className="-fake-center-bg-wrapper">
                                <svg viewBox="-10 -1 30 12">
                                    <defs>
                                        <linearGradient id="rectangleGradient" x1="0%" y1="0%" x2="0%" y2="100%">
                                            <stop offset="0%" stopColor="#225db9"/>
                                            <stop offset="100%" stopColor="#041d4a"/>
                                        </linearGradient>
                                    </defs>
                                    <path d="M-10 -1 H30 V12 H-10z M 5 5 m -5, 0 a 5,5 0 1,0 10,0 a 5,5 0 1,0 -10,0z"/>
                                </svg>
                            </div>

                            <div className="-right-wrapper">
                        <span className="-item-wrapper">
                            <span className="-ic-img">
                                <span className="-textfooter d-block">
                                    <Link to={'/promotion'}> {lgtext[LG]['7']}</Link>
                                </span>
                                <Link to={'/promotion'}>
                                     <img className={'img-fluid'}
                                          src={'/images/icon/promotion.png'} alt={'promotions'}/>
                                </Link>
                            </span>
                        </span>

                                <span className="-item-wrapper">
                            <span className="-ic-img">
                                <span className="-textfooter d-block">
                                    <a href="#">{lgtext[LG]['22']}</a>
                                </span>
                                <a onClick={() => handleShow()}>
                                    <img className={'img-fluid'}
                                         src={'/images/icon/menu-bar.png'}
                                         alt={'menu'}/>
                                </a>
                            </span>
                        </span>

                            </div>
                        </div>
                    </div>
                    }
                   

                </div>
            </div>

            <div className="linefixed" onClick={()=>navigate('/contact')}><img width="150" height="231" src="/images/linethai.png" className="image wp-image-2454  attachment-full size-full" alt="" decoding="async" loading="lazy" srcset="/images/linethai.png 150w, /images/linethai.png 32w" sizes="(max-width: 150px) 100vw, 150px" /></div>
        </>
    );
}

export default NavBar;
