import {useDispatch, useSelector} from "react-redux";
import { memberregister, bankregister, setAffcode} from "../store/user";
import { useRef, useEffect, useState} from "react";
import {Link} from "react-router-dom";
import Logo from "../components/Logo";
import { WEBTOKEN, LG } from '../config/Index.js'
import VedioMoal from '../components/ModelRegister'
const lgtext = {
    lao:{
      1:'ສະໝັກສະມາຊິກ',
      2:'ຍຸສເຊີ້',
      3:'ລະຫັດຜ່ານ',
      4:'ຢືນຢັນລະຫັດຜ່ານ',
      5:'ໃສ່ຂໍ້ມູນທະນາຄານ',
      6:'ໃສ່ຊື່ບັນຊີ',
      7:'ໃສ່ເລກບັນຊີ (ບໍ່ໃສ່ -)',
      8:'ໃສ່ຂໍ້ມູນສະກຸນເງິນ',
      9:'ໃສ່ເບີໂທ',
      10:'ຫຼືທ່ານມີບັນຊີຢູ່ແລ້ວ?',
      11:'ຫຼືທ່ານມີບັນຊີຢູ່ແລ້ວ?',
      12:'ໃສ່ໂຕອັກສອນພາສາອັງກິດເທົ່ານັ້ນ ແລະໂຕເລກ 5-12ໂຕ',
      22:'ໃສ່ໂຕອັກສອນພາສາອັງກິດເທົ່ານັ້ນ ແລະໂຕເລກ 5-12ໂຕ',
      33:'ໃສ່ໂຕອັກສອນພາສາອັງກິດເທົ່ານັ້ນ ແລະໂຕເລກ 5-12ໂຕ',
      13:'ລໍຖ້າຈັກໜ່ອຍ',
      14:'ຍຸສເຊີ້ ซ้ำ',
    },
    thai:{
      1:'สมัครสมาชิก',
      2:'ยูสเซอร์เนม (ใช้สำหรับล๊อกอิน)',
      22:'ป้อนภาษาอังกฤษและตัวเลขเท่านั้น',
      3:'รหัสผ่าน ',
      33:'ป้อน 6 ตัวอักษรขึ้นไป',
      4:'ยืนยันรหัสผ่าน',
      55:'ข้อมูลธนาคาร (ใช้สำหรับถอนเงิน)',
      5:'เลือกธนาคาร',
      6:'ชื่อบัญชี',
      7:'เลขบัญชี',
      8:'สกุลเงิน',
      9:'เบอร์โทร',
      10:'ท่านมีบัญชีอยู่แล้ว ?',
      11:'เข้าสู่ระบบ',
      13:'กำลังบันทึก',
      14:'ยูสเซอร์เนมซ้ำ กรูณาป้อนใหม่',
      15:'ป้อน (0 ถึง 9) เท่านั้นห้ามใส่ - ',
      16:'ป้อนชื่อ-นามสกุล',
    }
  }

 
function Register() {
   
    const user = useSelector(state => state.user)
    const dispatch = useDispatch();
    //const currncytype = LG === 'thai' ? [{id:'th', name:"บาท(THB)"},  {id:'dolla', name:"ดอลล่า(USD)"}] : [{id:'kip', name:"ກີບ(KIP)"},{id:'th', name:"ບາດ(THB)"},  {id:'dolla', name:"ດອນລ້າ(USD)"}]
    const currncytype = [{id:'kip', name:"ກີບ(KIP)"},{id:'th', name:"ບາດ(THB)"},  {id:'dolla', name:"ດອນລ້າ(USD)"}]
    useEffect(()=> {
        const params = new URLSearchParams(window.location.search) // id=123
        let code = params.get('affcode') // 123 
        dispatch(setAffcode(code))
        dispatch(bankregister({}))
    },[])
    const [cbank, setcbank] = useState()
    const [show, setshow] = useState(false)
    const [currency, setcurrency] = useState()
    const txtusername = useRef(null)
    const txtpassword = useRef(null)
    const txtcfpassword = useRef(null)
    const txtbank = useRef(null)
    //const txtcurrency = useRef(null)
    const txtaccname = useRef(null)
    const txtaccno = useRef(null)
    const txtmobile = useRef(null)
    const txtaffcode = useRef(null)

    const signup = () => {
        const username = txtusername.current.value;
        const password = txtpassword.current.value;
        const cfpassword = txtcfpassword.current.value;
        const bank = txtbank.current.value;
        //const currencyvalue = txtcurrency.current.value;
        const accno = txtaccno.current.value;
        const accname = txtaccname.current.value;
        const mobile = txtmobile.current.value;
        //const affcode = txtaffcode.current.value;
        if(!username){
            txtusername.current.focus()
        } else if(!password && username){
            txtpassword.current.focus()
        } else if(password !== cfpassword){
            txtcfpassword.current.focus()
        } else if(bank == 'TT'){
            setcbank('TT')
        }else if(!accname){
            txtaccname.current.focus()
        }else if(!accno){
            txtaccno.current.focus()
        }else if(!mobile){
            txtmobile.current.focus()
        } else {
            setcbank('APP')
            const data = {
                username,
                password,
                accno:accno,
                accname:accname,
                mobile,
                affcode:user?.affcode,
                fkbank:bank,
                webtoken:WEBTOKEN,
                currency:'th'
            }
            dispatch(memberregister(data));
        }
        
    }
    return (
        <div>
            {
                LG == 'thai' ? <div className="headregislogin mb-3">
                <div className="row m-0">
                    <div className="col-6 p-1">
                        <Link to={'/login'}>
                            <img className="gif" src="./images/loginbtn.gif"/>
                            <img className="png" src="./images/loginth.png"/>
                        </Link>
                    </div>
                    <div className="col-6 p-1 active">
                        <Link to={'/register'}>
                            <img className="gif" src="./images/regisbtn.gif"/>
                            <img className="png" src="./images/registh.png"/>
                        </Link>
                    </div>
                </div>
            </div> : <div className="headregislogin mb-3">
                <div className="row m-0">
                    <div className="col-6 p-1">
                        <Link to={'/login'}>
                            <img className="gif" src="./images/user44/logingiff1.gif"/>
                            <img className="png" src="./images/login.png"/>
                        </Link>
                    </div>
                    <div className="col-6 p-1 active">
                        <Link to={'/register'}>
                            <img className="gif" src="./images/user44/regisgifflaos1.gif"/>
                            <img className="png" src="./images/regis.png"/>
                        </Link>
                    </div>
                </div>
            </div>
            }
            

            <section className="sectionpage login">
                <div className="inbgbeforelogin">
                    
                        {
                            LG == 'thai' ? <div className="logopopup"><Logo/></div> : <div className="logopopup999"><div className='text-center pt-3' onClick={()=>setshow(true)}><img src={`/images/howto/register.png`} height={90} /></div></div>
                        }
                
                    <h1>{lgtext[LG]['1']}</h1>
                        {
                            user?.affcode && user?.affcode.length > 0 && <div>รหัสผู้แนะนำ</div>
                        }
                         {
                            user?.affcode && user?.affcode.length > 0 && <input className="mb-3 affcode" ref={txtaffcode} name="affcode" disabled type="text" placeholder={user?.affcode} value={user?.affcode}/>
                        }
                    
                        

                        <div>{lgtext[LG]['2']}</div>
                        <input className="mb-3" ref={txtusername} name="username" type="text"
                               placeholder={lgtext[LG]['22']}/>
                               {
                                user?.errcode == 403 &&  <div className="mb-3 text-danger text-center">{lgtext[LG]['14']}</div>
                               }
                         <div>{lgtext[LG]['3']}</div>     
                        <input className="mb-3" ref={txtpassword}  name="password" type="password"
                               placeholder={lgtext[LG]['33']}/>
                                {
                                user?.errcode == 422 &&  <div className="mb-3 text-danger text-center">ป้อนรหัสผ่าน 5 หลักขึ้นไป</div>
                               }
                        <div>{lgtext[LG]['4']}</div>
                        <input className="mb-3" ref={txtcfpassword}  name="cfpassword" type="password"
                               placeholder={lgtext[LG]['33']}/>
                               <div>{lgtext[LG]['55']}</div>
                        <select className="mb-3" name="banks" ref={txtbank} onChange={()=>setcbank('APP')} >
                            <option value="TT">{lgtext[LG]['5']}</option>
                            {
                                user?.bankregis && user?.bankregis.filter(x=>x.name !=='Gtech').map(x=><option value={x._id} key={x._id}>{x.name}</option>)
                            }
                        
                        </select>
                        {
                            cbank == 'TT' && <div className="mb-3 text-danger text-center">{lgtext[LG]['5']}</div>
                        }
                         <div>{lgtext[LG]['6']}</div>
                        <input className="mb-3" ref={txtaccname}  name="banksaccname" type="text"
                               placeholder={lgtext[LG]['16']}/>
                                <div>{lgtext[LG]['7']}</div>
                                <input className="mb-3" ref={txtaccno}  name="banksnumber" type="text"
                               placeholder={lgtext[LG]['15']}/>
                                {
                                user?.errcode == 411 &&  <div className="mb-3 text-danger text-center">เลขบัญชี ซ้ำ</div>
                               }
                             
                        {
                            currency == 'TT' && <div className="mb-3 text-danger text-center">{lgtext[LG]['8']}</div>
                        }
                         <div>{lgtext[LG]['9']}</div>
                            <input className="mb-3" ref={txtmobile}  name="mobile" type="text" placeholder={lgtext[LG]['15']}/>
                            {
                                user?.errcode == 412 &&  <div className="mb-3 text-danger text-center">{lgtext[LG]['9']} ซ้ำ</div>
                               }
                               {
                                user?.isLoadingLogin == true ? <button className="btnLogin" id="btnLogin">{lgtext[LG]['13']}</button>:<button onClick={signup} className="btnLogin" id="btnLogin">{lgtext[LG]['1']}</button>
                               }
                        
                   
                    <div className="modalspanbox mb-3">{lgtext[LG]['10']}
                        <Link to={'/login'} className="ms-3 loginbtn"> {lgtext[LG]['11']}</Link>
                    </div>
                </div>
            </section>
          <VedioMoal filename='register' show={show} onHide={()=>setshow(false)}/>

        </div>
    );
}

export default Register;
